import request from "../Requests/requests";
import { editSettings } from "../store/settingsReducer";

export const changingSettings = (payload) => {
    return async dispatch => {
        await request.put(`/Settings/`, { 
            userId: localStorage.getItem('token'),
            settingsType: payload.settingsType,
            isSmartDateRecognition: payload.isSmartDateRecognition,
            isTimeTracking: payload.isTimeTracking,
            timeZone: payload.timeZone,
            currentTimeZone: payload.currentTimeZone
            })
        dispatch(editSettings(payload));
    }
}