import React, { useState } from "react"
import Button from "../../../../UIcomponents/Button/Button"
import './Integrations.css'
import request from "../../../../Requests/requests"

export default function Integrations() {
    const [loaderGoogle, setLoaderGoogle] = useState<boolean>(false)
    const stylesBtn = {
        fontSize: '15px',
        lineHeight: 1,
        marginTop: '24px'
    }

    const GetUrlGoogle = () => {
        setLoaderGoogle(true)
        request.get('/GoogleCalendar/Url')
        .then(response => {
            setLoaderGoogle(false)
            window.location.href = `${response.data}`
        }).catch(error => {
            setLoaderGoogle(false)
            console.log(error)
        })
    }

    return (
        <div className="integrations">
            <ul className="integrations__list">
                <li className="integrations__list-item">
                    <h3 className="integrations__list-item__heading">Интеграция с Telegram-bot</h3>
                    <p className="integrations__list-item__description">Будут приходить напоминания в 
                    telegram-bot о запланированных задачах, мероприятиях и встречах.
                        Так ты никогда не пропустишь то, что запланировал на день.</p>
                    <Button stylesButton={stylesBtn} title={'Подключить'} disabled={true}></Button>
                </li>
                <li className="integrations__list-item">
                    <h3 className="integrations__list-item__heading">Интеграция с Google Календарь</h3>
                    <p className="integrations__list-item__description">Все задачи, которые ты поставишь в timetask.ru, 
                    попадут в Google календарь и наоборот.</p>
                    <div>
                        <Button stylesButton={stylesBtn} title={'Подключить'} onClickFunc={GetUrlGoogle} loading={loaderGoogle}/>
                    </div>

                </li>
            </ul>
        </div>
    )
}